var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("guide", {
        ref: "guide",
        attrs: {
          addPermissionString: "jc:add",
          editPermissionString: "jc:edit",
          deletePermissionString: "jc:delete",
          viewPermissionString: "jc:view",
          "is-preview": "",
          "is-mask-closable": "",
          "hide-edit-btn": "",
          "props-filters": { typeId: 3 },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }